import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";

import { makeStyles } from "@material-ui/core/styles";

import { ButtonBase } from "@material-ui/core";

const chinaFlag = "🇨🇳";

// copied from Appbar.js oops
const useStyles = makeStyles(theme => ({
  titleButton: {
    padding: theme.spacing(1),
    borderRadius: "4px",
    transition: "background-color .125s ease",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.25)"
    },
    "&:first-child": {
      // Site title.
      fontFamily:
        "Work Sans, -apple-system, BlinkMacSystemFont, Roboto, sans-serif"
    }
  }
}));

const displayLang = code => {
  return {
    en: "English",
    zh: `${chinaFlag}中文`
  }[code];
};

export default ({ subsite: { key: subsiteKey } }) => {
  const classes = useStyles();

  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              subsites {
                key
                lang
                country
                prefixPath
              }
            }
          }
        }
      `}
      render={({
        site: {
          siteMetadata: { subsites }
        }
      }) => {
        const subsite = subsites.find(s => s.key === subsiteKey);
        const translations = subsites.filter(
          s => s.lang !== subsite.lang && s.country === subsite.country
        );

        return translations.map(s => (
          <ButtonBase
            component={Link}
            to={`${s.prefixPath}/`}
            classes={{ root: classes.titleButton }}
            key={s.key}
          >
            {displayLang(s.lang)}
          </ButtonBase>
        ));
      }}
    />
  );
};
