import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import Link from "../components/Link";
import CountryPicker from "./CountryPicker";
import { useTranslation } from "react-i18next";

// import logoZH from "../../../assets/zh/logo-for-dark-bg.svg";
import logoZH from "../../../assets/zh/logo-white.svg";
import logoEN from "../../../assets/en/logo-white.svg";
import cryptoAcceptedHereImage from "../../../assets/crypto-accepted.png";
// import logoEN from "../../../assets/zh/logo-for-dark-bg.svg";

const useStyles = makeStyles(theme => ({
  footer: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[900],
    "& a": {
      color: theme.palette.grey[300],
      textDecoration: "none",
      fontSize: ".9rem"
    },
    "& a:hover": {
      textDecoration: "underline"
    },
    "& ul": {
      padding: 0,
      listStyle: "none"
    },
    "& li": {
      marginBottom: theme.spacing(0.5)
    }
  }
}));

const FooterColumns = ({ columns, subsite }) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      {columns.map(column => {
        return (
          <Grid xs={12} sm={4} item key={column.heading}>
            <Typography style={{ fontWeight: "bold" }}>
              {column.heading}
            </Typography>
            <ul>
              {column.links.map(link => {
                return (
                  <li key={link.title}>
                    <Link to={link.url}>{link.title}</Link>
                  </li>
                );
              })}
            </ul>
          </Grid>
        );
      })}
      <Grid xs={12} sm={4} item key="other-sites">
        {/*<Typography style={{ fontWeight: "bold" }}>{t("allSites")}</Typography>*/}
        <CountryPicker subsite={subsite} />
      </Grid>
    </Grid>
  );
};

export default ({ subsite }) => {
  const classes = useStyles();

  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              subsites {
                key
                lang
                components {
                  footer {
                    columns {
                      heading
                      links {
                        title
                        url
                      }
                    }
                    copyright
                  }
                }
              }
              components {
                footer {
                  columns {
                    heading
                    links {
                      title
                      url
                    }
                  }
                  copyright
                }
              }
            }
          }
        }
      `}
      render={({
        site: {
          siteMetadata: { subsites }
        }
      }) => {
        const currentSubsite = subsites.find(s => s.key === subsite.key);
        const {
          components: {
            footer: { columns, copyright }
          }
        } = currentSubsite;

        const logo = currentSubsite.lang === "zh" ? logoZH : logoEN;
        return (
          <Box component="footer" classes={{ root: classes.footer }}>
            <Container maxWidth="md">
              <Box padding={4}>
                <FooterColumns columns={columns} subsite={subsite} />
                <Box textAlign="center" marginTop={2}>
                  <img
                    alt="cryptocurrency accepted here"
                    src={cryptoAcceptedHereImage}
                    height="45"
                  />
                </Box>
                <Box textAlign="center" marginTop={2}>
                  <img src={logo} alt={copyright} height="50px" />
                </Box>
                <Box textAlign="center" marginTop={1}>
                  <Typography variant="caption" style={{ opacity: 0.5 }}>
                    &copy; {new Date().getFullYear()} {copyright}
                  </Typography>
                </Box>
              </Box>
            </Container>
          </Box>
        );
      }}
    />
  );
};
