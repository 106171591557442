import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Box,
  ButtonBase,
  IconButton,
  Toolbar,
  useMediaQuery
} from "@material-ui/core";

import logoZH from "../../../assets/zh/logo-for-dark-bg.svg";
import logoEN from "../../../assets/en/logo-for-dark-bg.svg";
// import logo from "../../../assets/logo-for-dark-bg-transparent-globe.svg";
import { MdMenu } from "react-icons/md";
import LanguagePicker from "../components/LanguagePicker";

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(1)
  },
  titleButton: {
    padding: theme.spacing(1),
    borderRadius: "4px",
    transition: "background-color .125s ease",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.25)"
    },
    "&:first-child": {
      // Site title.
      fontFamily:
        "Work Sans, -apple-system, BlinkMacSystemFont, Roboto, sans-serif"
    }
  },
  phoneButton: {
    padding: theme.spacing(1),
    borderRadius: "4px"
  },
  ctaButton: {
    padding: theme.spacing(1.2),
    borderRadius: "4px",
    transition: "background-color .125s ease",
    backgroundColor: "#b71a1a",
    fontWeight: "bold",
    color: "#fff",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.25)"
    },
    "&:first-child": {
      // Site title.
      fontFamily:
        "Work Sans, -apple-system, BlinkMacSystemFont, Roboto, sans-serif"
    }
  }
}));

const AppBarLinks = ({ links }) => {
  const classes = useStyles();

  return links.map(link => {
    return (
      <ButtonBase
        component={Link}
        to={link.url}
        classes={{ root: classes.titleButton }}
        key={link.title}
      >
        {link.title}
      </ButtonBase>
    );
  });
};

export default ({ elevation = 1, onToggleDrawer, subsite }) => {
  const classes = useStyles();
  const isXs = useMediaQuery(theme => theme.breakpoints.down("xs"));

  const { prefixPath } = subsite;
  const logo = subsite.lang === "zh" ? logoZH : logoEN;

  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              phoneNumber
              title
              subsites {
                key
                components {
                  appbar {
                    position
                    phoneText
                    links {
                      title
                      url
                    }
                    cta {
                      url
                      title
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({
        site: {
          siteMetadata: { title, phoneNumber, subsites }
        }
      }) => {
        const {
          components: {
            appbar: { links, position, cta /*, phoneText*/ }
          }
        } = subsites.find(s => s.key === subsite.key);

        return (
          <AppBar color="primary" position={position} elevation={elevation}>
            <Toolbar>
              {isXs && (
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={onToggleDrawer}
                >
                  <MdMenu />
                </IconButton>
              )}
              <Box display="flex" flexGrow={1}>
                <ButtonBase
                  component={Link}
                  to={`${prefixPath}/`}
                  classes={{ root: classes.titleButton }}
                >
                  <img src={logo} alt={title} height="45px" />
                </ButtonBase>
                <LanguagePicker subsite={subsite} />
              </Box>
              {// Display the appbar action links if the media query breakpoint is larger than Xs.
              !isXs && <AppBarLinks links={links} />}

              {/* todo: wechat/qq logos which popup qr code? or write directly id in header */}
              {/*
                // remove tel unti we have one....
              <ButtonBase
                component="a"
                href={`tel:${phoneNumber}`}
                classes={{ root: classes.phoneButton }}
                key="tel"
              >
                {!isXs && phoneNumber}
                {isXs && phoneText}
              </ButtonBase>
              */}

              <ButtonBase
                component={Link}
                to={cta.url}
                classes={{ root: classes.ctaButton }}
                key="cta"
              >
                {cta.title}
              </ButtonBase>
            </Toolbar>
          </AppBar>
        );
      }}
    />
  );
};
