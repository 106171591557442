import React from "react";
import { Link } from "gatsby";

const isExternalUrl = url => {
  return [
    url.startsWith("//"),
    url.startsWith("mailto:"),
    url.startsWith("tel:"),
    url.startsWith("https:"),
    url.startsWith("http:")
  ].some(_ => _);
};

export default ({ to, children }) => {
  // Open external links in a new browser tab and internal links using Gatsby's router.
  return isExternalUrl(to) ? (
    <a href={to} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  ) : (
    <Link to={to}>{children}</Link>
  );
};
