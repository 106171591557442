import React, { useState } from "react";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import theme from "../style/theme";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { useSetupI18n } from "../i18n";
import "../style/layout.css";

const maybeDomainRedirect = ({ lang }) => {
  if (typeof window === "undefined") return;
  const {
    location: { protocol, search, hash, host, hostname, pathname }
  } = window;

  if (hostname === "localhost") return;

  const redirect = domain => {
    if (domain === hostname) return;
    window.location.href = `${protocol}//${domain}${pathname}${search}${hash}`;
  };

  if (lang === "zh") {
    return redirect("waihuzhao.com");
  }
  redirect("nationleap.com");
};

export default ({ elevateAppBar = true, children, subsite }) => {
  useSetupI18n(subsite);
  maybeDomainRedirect(subsite);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleToggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{subsite.title}</title>
        <description>{subsite.description}</description>
      </Helmet>
      <CssBaseline />
      <Appbar
        subsite={subsite}
        onToggleDrawer={handleToggleDrawer}
        elevation={Number(elevateAppBar)}
      />
      {children}
      <Footer subsite={subsite} />
      <Drawer
        subsite={subsite}
        open={isDrawerOpen}
        onClose={handleToggleDrawer}
      />
    </ThemeProvider>
  );
};
