import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { StaticQuery, graphql, Link } from "gatsby";
import { useTranslation } from "react-i18next";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    "&:focus": {
      // backgroundColor: theme.palette.common.grey,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

const groupSubsitesByCountry = subsites => {
  const obj = {};
  subsites.forEach(s => {
    obj[s.country] = obj[s.country] || [];
    obj[s.country].push(s);
  });
  return Object.keys(obj).map(key => obj[key]);
};

export default function CountryPicker({ subsite }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              subsites {
                key
                country
                countryName
                lang
                prefixPath
              }
            }
          }
        }
      `}
      render={({
        site: {
          siteMetadata: { subsites }
        }
      }) => {
        const currentSubsite = subsites.find(s => s.key === subsite.key);

        const subsitesByCountry = groupSubsitesByCountry(subsites);
        return (
          <div>
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              color="secondary"
              onClick={handleClick}
            >
              {t("allSites")}
            </Button>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {subsitesByCountry.map(subsites => {
                const s =
                  subsites.find(s => s.lang === currentSubsite.lang) ||
                  subsites.find(s => s.lang === "en");
                let label = s.countryName;
                if (s.lang !== currentSubsite.lang) {
                  label = `${label} (${s.lang})`;
                }
                return (
                  <StyledMenuItem
                    button
                    key={s.key}
                    component={Link}
                    to={`${s.prefixPath}/`}
                  >
                    <ListItemText primary={label} />
                  </StyledMenuItem>
                );
              })}
            </StyledMenu>
          </div>
        );
      }}
    />
  );
}
